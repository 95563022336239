import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ILicenseInfoModel, StagingState } from '../ILicenseInfoModel';
import { Location } from '@angular/common';
import { ProgressTrackerComponent } from '../progress-tracker/progress-tracker.component'

import { LicenseService } from '../license.service';
import { StagerService } from '../stager.service'
import { ComponentCanBeDeactivated } from '../can-exit-without-saving.guard';
import { MeasuresService } from '../measures.service';
import { MeasuresEvent } from 'src/dataModel/measuresEvent.model';

@Component({
    selector: 'app-licensenotrecognized',
    templateUrl: './licensenotrecognized.component.html',
    styleUrls: ['./licensenotrecognized.component.css']
})
export class LicensenotrecognizedComponent implements OnInit, ComponentCanBeDeactivated {

    license: ILicenseInfoModel;

    licenseStagingPath: string;
    toggle_value: string;
    approvedLicenseMessage: string;
    searchFilter: string;
    showSuccessModal: boolean = false;
    showErrorModal: boolean = false;
    isLoadingSearchBox: boolean = false;
    public isLoading: boolean;
    public nextBtnDisabled: boolean;
    public nextBtnAlreadyClicked: boolean;
    public expiredDate: boolean = false;
    comments: string;

    @ViewChild(ProgressTrackerComponent) progressTracker: ProgressTrackerComponent;

    constructor(private stagerService: StagerService, private licenseService: LicenseService, private _location: Location, private measuresService: MeasuresService, ) { }

    ngOnInit() {
        this.license = this.licenseService.getLicense();

        this.searchFilter = 'Search';
        this.nextBtnDisabled = true;
        this.nextBtnAlreadyClicked = false;
        
        this.measuresService.sendEvent(new MeasuresEvent('License not recognized')).subscribe();
    }
    onYesClicked() {
        if (this.nextBtnAlreadyClicked)
            return;

        this.nextBtnDisabled = false;
        this.showSuccessModal = true;
        this.showErrorModal = false;
        document.getElementById('btn-next').classList.replace('next-btn-disabled', 'next-btn-enabled');
        this.nextBtnDisabled = false;
    }

    onNoClicked() {
        this.nextBtnDisabled = false;
        this.showSuccessModal = false;
        this.showErrorModal = true;
        document.getElementById('btn-next').classList.replace('next-btn-disabled', 'next-btn-enabled');
        this.nextBtnDisabled = false;
    }

    onModalSuccessClicked() {
        this.nextBtnDisabled = true;
        this.nextBtnAlreadyClicked = true;
        document.getElementById('btn-next').classList.replace('next-btn-enabled', 'next-btn-disabled');
        document.getElementById('div-toggle').classList.add('toggle-btn-disabled');
        this.progressTracker.setCompleted();
    }

    onNext() {
        if (this.nextBtnDisabled || this.nextBtnAlreadyClicked)
            return;

        this.nextBtnAlreadyClicked = true;

        if (this.toggle_value == 'Yes') {
            this.changeLicenceState(StagingState.Manual)
            this.showSuccessModal = true;
            this.showErrorModal = false;
            console.log("Approved installation");
        } else {
            this.changeLicenceState(StagingState.Cancelled)
            this.showErrorModal = true;
            this.showSuccessModal = false;
            console.log("Cancelled installation");
        }

        this.approvedLicenseMessage = "The license will be installed manually";
        if (this.license.StartWindow && this.license.EndWindow)
            this.approvedLicenseMessage += " in the hours between " + this.license.StartWindow.toString() +
                " to " + this.license.EndWindow.toString();
        this.approvedLicenseMessage += ".";

        if (this.showSuccessModal)
            document.getElementById('success-modal-container').classList.replace('success-modal-hidden', 'success-modal-visible');
        else
            document.getElementById('success-modal-container').classList.replace('success-modal-visible', 'success-modal-hidden');

        if (this.showErrorModal)
            document.getElementById('error-modal-container').classList.replace('error-modal-hidden', 'error-modal-visible');
        else
            document.getElementById('error-modal-container').classList.replace('error-modal-visible', 'error-modal-hidden');
    }

    onBack() {
        this._location.back();
    }

    onGoHome() {
    }

    canDeactivate(): boolean {
        return this.nextBtnAlreadyClicked;
    }

    changeLicenceState(state: StagingState, loading: boolean = true) {
        if(loading)
            this.isLoading = true;
        this.license.StagingState = state
        
        this.stagerService.putUserConfirmation(this.license).subscribe(
            _ => {
                if(loading)
                    this.isLoading = false;
                this.licenseService.updateLicenseWithoutReloading(this.license)
                console.log(this.license);
            }
        )
    }

    performTaskForDeactivation() {
        this.changeLicenceState(StagingState.Cancelled, false)
    }

    @HostListener('window:beforeunload', ['$event'])
    unload($event: any) {
        if (!this.canDeactivate())
            $event.returnValue = true
    }
}
