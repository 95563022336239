import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { Observable } from "rxjs";
import { MeasuresEvent } from "src/dataModel/measuresEvent.model";

import { measuresRegistrationId } from '../environments/environment';
import { AppConfigService } from "./configuration/app-config.service";

@Injectable({
    providedIn: 'root'
})

export class MeasuresService {
    //unique value identifying users in EMCloud AD
    userEmail: string;

    constructor(private http: HttpClient, private authService: MsalService) {
        if (this.authService.getUser()) {
            this.userEmail = this.authService.getUser().displayableId;
        }
    }

    public sendEvent(event: MeasuresEvent): Observable<Object> {
        let now = new Date();
        let jan = new Date(now.getFullYear(), 0, 1);
        let jul = new Date(now.getFullYear(), 6, 1);
        let offset = -1 * Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset()) / 60;

        let measuresEvent = {
            ApplicationName: 'License Automation Tool',
            AppRegistrationId: measuresRegistrationId,
            UserId: this.userEmail,
            BaseUrl: location.origin,
            TimeDiff: offset,
            StartTime: now.toISOString(),
            ...event
        };

        let apiURL = AppConfigService.settings.licenseapi+"Measures/web-app";

        return this.http.post(apiURL, [
            measuresEvent
        ]);
    }
}