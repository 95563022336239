import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from '../authentication/auth-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StagerService } from '../stager.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.css']
})
export class AuthCallbackComponent implements OnInit {

  constructor(private _router: Router, private _authService: AuthService, private  _zone: NgZone, private _activated: ActivatedRoute, private _stagerService : StagerService) { }

  ngOnInit() {
    this._authService.completeAuthentication();
    this.navigate()
  }

  private navigate(){
    if(window === window.parent){
      setTimeout(()=> {
        this._zone.run(()=> this._router.navigate(['/']));
        //window.location.replace(location.origin + location.hash)
      },1000);
    }
  }

}
