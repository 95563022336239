import { HttpErrorInterceptor } from './../http-error.interceptor';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input } from "@angular/core";
import { AuthService } from "../authentication/auth-service.service";
import { AuthorizationService } from "../authorization/authorization.service";
import { Roles } from "../roles";
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Component({
  selector: "app-error-page",
  templateUrl: "./error-page.component.html",
  styleUrls: ["./error-page.component.css"]
})

export class ErrorPageComponent implements OnInit {
  activeuser: any;
  isAdmin: boolean;
  authenticated: boolean;
  error: HttpErrorResponse;
  erorrinterceptor: HttpErrorInterceptor;
  state$: HttpErrorResponse;
  errormessage:string;
  errortype: string;
  errorheaders: string;
  errorname: string;
  errorstatus: string;
  errorok: string;
  errorstatustext: string;
  errorurl: string;
  errorstring:string;
  constructor(
    private authService: AuthService,
    private jwt: AuthorizationService,
    public activatedRoute: ActivatedRoute
      
  ) {}


  ngOnInit() {
    this.activeuser = this.authService.getName();
    this.authenticated = this.authService.authenticated;
    if (Roles.LMSupport === this.jwt.getRole(this.authService.getCachedToken())) {
      this.isAdmin = true;
    } else {this.isAdmin = false; }

    this.activatedRoute.paramMap.subscribe(params => {
     
      this.state$ = JSON.parse(params.get('state'));
      
     
     this.errormessage = this.state$.message || '';
     this.errorname = this.state$.name || '';
     this.errorok   = (this.state$.ok || '').toString();
     this.errorstatus = (this.state$.status || '').toString();
     this.errorstatustext = this.state$.statusText || '';
   
     this.errorurl = this.state$.url || '';
     this.errorstring = params.get('state') || '';
    });

    

  }


}

