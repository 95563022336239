import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITransactionsService } from '../Models/itransactions.service';
import { map } from 'rxjs/operators';
import { AppConfigService } from '../configuration/app-config.service';

// Set the http options
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class AdmingridService {


  constructor(private http: HttpClient) { }

  /*getTrans(): Observable<ITransactionsService[]> {
    return this.http.get<ITransactionsService[]>(AppConfigService.settings.licenseapi+"Grid", httpOptions).pipe(
      map(this.extractData),
    );
  }*/

  getTrans(): Observable<ITransactionsService[]> {
    let apiURL = AppConfigService.settings.licenseapi+"Grid";
    return this.http.get<ITransactionsService[]>(apiURL)
  }

  /**
* Function to extract the data when the server return some
*
* @param res
*/
  private extractData(res: Response) {
    //console.log('Res: ' + res);
    const body = res;
    return body || {};
  }



}
