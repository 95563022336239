import { Component, OnInit, Input } from '@angular/core';
import { ILicenseInfoModel } from '../ILicenseInfoModel';
import { AssemblyResult, IComparedElement, IDifference } from '../ILicenseInfoModel';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { IApp } from '../ILicenseInfoModel';
import { startWith, debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { CartaService } from '../cartaservice';
import { ICartaApplication } from '../Models/icartaapplication';
import { of } from 'rxjs';
import { AuthService } from '../authentication/auth-service.service'
import { AuthorizationService } from '../authorization/authorization.service';



@Component({
    selector: 'app-license-comparison',
    templateUrl: './licensecomparison.component.html',
    styleUrls: ['./licensecomparison.component.css']
})

export class LicenseComparisonComponent implements OnInit {
    public activeuser: any;
    public isUser: boolean;
    public authenticated: boolean;
    public emptycomparison: boolean =false;

    @Input() license: ILicenseInfoModel;
    @Input() isCartaEditable: boolean;

    public isLoadingSearchBox: boolean = false;
    public cartaForm: FormGroup;
    public cartaApplications: ICartaApplication[][] = [];
    public selectedCartaApplication: ICartaApplication[] = [];

    constructor(private fb: FormBuilder, private cartaService: CartaService, private authService: AuthService, private jwt: AuthorizationService ) { }

    ngOnInit() {
        if(this.isCartaEditable){
            this.createForm();
            this.loadCartaManagers();
        }
        this.activeuser = this.authService.getName(); 
        this.authenticated = this.authService.authenticated;    
        if(this.jwt.getRole(this.authService.getCachedToken()) == 'User' ){
             this.isUser = true;
        }
    }

    createForm(){
        this.cartaForm = this.fb.group({
            cartaIDs : this.buildCartaIDs()
        });
    }

    getFeatureCssClass(feature: IComparedElement): string {
        switch (feature.AssemblyResult) {
            case AssemblyResult.OnlyNewFound:
                return "only-new-found"
            case AssemblyResult.BothWillBeAdded:
                return "both-will-be-added"
            case AssemblyResult.NewReplacesOld:
                return "new-replaces-old"
            case AssemblyResult.NewReplacesOldWithLessSeats:
                return "new-replaces-old-with-less-seats"
            case AssemblyResult.NewReplacesOldWithLessDate:
                return "new-replaces-old-with-less-date"
            case AssemblyResult.NewReplacesOldWithLessSeatsAndDate:
                return "new-replaces-old-with-less-seats-and-date"
            case AssemblyResult.NewCantReplaceBecauseOfStartDate:
                return "new-cant-replace-because-of-date"
            case AssemblyResult.NewExpired:
                if (this.featureIsEmpty(feature))
                    return "new-expired"
                else
                    return "new-cant-replace-because-of-date"
        }
    }

    buildCartaIDs(){
        var formArray = this.fb.array([]);
        
        for (let i = 0; i < this.license.License.Apps.length; i++) {
            if(this.license.License.Apps[0].Features.length == 0)
            {
                this.emptycomparison = true;
            }
            formArray.push(this.fb.group({
                cartaID : {value: '', disabled: !this.isCartaEditable}
            }));
        }
        return formArray;
    }

    featureIsEmpty(feature: IComparedElement) {
        return feature.DifferencesFound.every(d => d.Value1 == "")
    }

    mustShowFeature1(feature: IComparedElement): boolean {
        return feature.AssemblyResult != AssemblyResult.OnlyNewFound && !this.featureIsEmpty(feature);
    }

    mustShowWarningIcon(feature: IComparedElement, difference: IDifference): boolean {

        var isAConcreteWarningForDate = feature.AssemblyResult == AssemblyResult.NewReplacesOldWithLessDate ||
            feature.AssemblyResult == AssemblyResult.NewReplacesOldWithLessSeatsAndDate
        var isAConcreteWarningForSeats = feature.AssemblyResult == AssemblyResult.NewReplacesOldWithLessSeats ||
            feature.AssemblyResult == AssemblyResult.NewReplacesOldWithLessSeatsAndDate;

        return difference.AttributeName == "Expiration Date" && isAConcreteWarningForDate || difference.AttributeName == "Seats" && isAConcreteWarningForSeats;
    }

    mustShowErrorIcon(feature: IComparedElement, difference: IDifference): boolean {
        return difference.AttributeName == "Expiration Date" &&
            (feature.AssemblyResult == AssemblyResult.NewCantReplaceBecauseOfStartDate || feature.AssemblyResult == AssemblyResult.NewExpired);
    }

    mustShowPlusIcon(feature: IComparedElement): boolean {
        return feature.AssemblyResult == AssemblyResult.BothWillBeAdded;
    }

    mustShowCommentOfSeatsWarning(difference: IDifference): boolean {
        return difference.AttributeName == "Seats";
    }

    mustShowCommentOfDateWarning(difference: IDifference): boolean {
        return difference.AttributeName == "Expiration Date";
    }


    loadCartaManagers() {
        for (let i: number = 0; i < this.license.License.Apps.length; i++) {
            this.manageCartaIDSearchBox(i);
        }
    }


    manageCartaIDSearchBox(index: number) {
        var arrayControl = this.cartaForm.get('cartaIDs') as FormArray;

        arrayControl.at(index).get('cartaID').valueChanges
            .pipe(
                startWith(''),
                debounceTime(1000),
                tap(() => {
                    this.cartaApplications[Number(index)] = [];
                    this.isLoadingSearchBox = true;
                }),
                switchMap((value) => ((value != undefined && value.length >= 3 && (this.selectedCartaApplication[Number(index)] == null || (value != this.selectedCartaApplication[Number(index)].ID))) ? this.cartaService.getCartaApplications(value) : of([]))
                    .pipe(
                        finalize(() => {
                            this.isLoadingSearchBox = false
                        }),
                    )
                )
            )
            .subscribe(data => {
                if (data == undefined) {
                    this.cartaApplications[Number(index)] = [];
                } else {
                    this.cartaApplications[Number(index)] = data
                    this.cartaApplications[Number(index)] = this.cartaApplications[Number(index)].filter(obj => obj.ID != null).sort((app1, app2) => {
                        if (app1.Version < app2.Version) {
                            return 1;
                        }

                        if (app1.Version > app2.Version) {
                            return -1;
                        }

                        return 0;
                    });
                }
            },
                (err: any) => console.log('Error while trying to search Carta ID.'));
    }

    cartaApplicationClick(index: number, application: IApp, event: any) {
        let selectedCartaID = event.option.value;
        this.selectedCartaApplication.splice(index, 0, this.cartaApplications[Number(index)].filter(x => x.ID == selectedCartaID)[0]);
        this.cartaApplications[Number(index)] = [];
        this.license.License.Apps.find(x => x === application).CartaId = this.selectedCartaApplication[Number(index)].ID;
    }

    checkCartaID(index: number, application: IApp) {
        var arrayControl = this.cartaForm.get('cartaIDs') as FormArray;
        if (this.selectedCartaApplication[Number(index)] == null || this.selectedCartaApplication[Number(index)].ID == null || this.selectedCartaApplication[Number(index)].ID != arrayControl.at(index).get('cartaID').value) {
            arrayControl.at(index).get('cartaID').patchValue('');
            this.selectedCartaApplication[Number(index)] = null;
            this.license.License.Apps.find(x => x === application).CartaId = '';
        }
    }

    displayFn(cartaApplicationID: string) {
        return cartaApplicationID ? cartaApplicationID : '';
    }

}
