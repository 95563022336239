import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ICartaApplication } from './Models/icartaapplication';
import { map, catchError, tap } from 'rxjs/operators';
import { AppConfigService } from './configuration/app-config.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json',
                            'Skip': 'true' })
  };

@Injectable({
  providedIn: 'root'
})

export class CartaService {

  public isAlive: boolean; // This variable should be read-only.

  constructor(private http: HttpClient) {

   }

    isCartaAlive() : Observable<boolean> {
      return this.http.get<ICartaApplication[]>(AppConfigService.settings.licenseapi + "Carta/Application/carta", httpOptions)
        .pipe(
          map(result => {
           return true;
        }),
        catchError(error => {
          return of(false);
        }));
    }

        getCartaApplications(appNameOrId: string): Observable<any> {
          return this.http.get<ICartaApplication>(AppConfigService.settings.licenseapi + "Carta/Application/" + appNameOrId, httpOptions).pipe(
            tap(_ => console.log(`fetched app name or id: ${appNameOrId}`),
            map(this.extractData)
          ));
        }


  /**
* Function to extract the data when the server return some
*
* @param res
*/
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }


}