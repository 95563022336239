// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const LATBaseUrl = 'https://licmgmtapi.development.lm.xomsvcs.com/';
export const measuresRegistrationId = '8a530f7683224fc295dfb20423da29c7';

export const protectedResourceMap: [string, string[]][] = [
    [
        'https://graph.microsoft.com/v1.0/me',
        [
            'user.read'
        ]
    ],
    [
        LATBaseUrl,
        [
            'https://UTCSDevTst.onmicrosoft.com/Dev-LicenseAPI/user_impersonation'
        ]
    ]
];

export const environment = {
  production: false,
  msalconfig: {
      clientID: '72c6d9ba-00ce-4614-82a0-49e48836a966',
      consentScopes: [
          'user.read',
          'openid',
          'profile',
          'https://UTCSDevTst.onmicrosoft.com/Dev-LicenseAPI/user_impersonation'
      ],
      authority: 'https://login.microsoftonline.com/d1ee1acd-bc7a-4bc4-a787-938c49a83906/',
      protectedResourceMap,
      redirectUri: 'https://licenseautomation.development.lm.xomsvcs.com',
      //redirectUri: 'http://localhost:4200/auth-callback',
      storeAuthStateInCookie: true,
      cacheLocation: 'localStorage',
    },
}







/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
