import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';
import { AuthService } from './auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _authService: AuthService,private router: Router){}

  canActivate():boolean{
    if (this._authService.authenticated){
      let url = window.localStorage.getItem('redirectUrl');
      window.localStorage.removeItem('redirectUrl');
      if (url)
        this.router.navigate([url]);
      return true;
    }
    else{
      window.localStorage.setItem('redirectUrl',window.location.pathname+window.location.search);
      this._authService.login();
      return false;
    }
  }
}
