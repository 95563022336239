import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Http} from '@angular/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ILicenseInfoModel } from './ILicenseInfoModel';
import { ITransactionsService } from './Models/itransactions.service';
import { Response } from 'selenium-webdriver/http';
import { environment } from 'src/environments/environment';
import { AppConfigService } from './configuration/app-config.service';

// Set the http options
const httpOptions = {
  headers: new HttpHeaders({
  })
};

const httpOpt = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};
const httpOptionsToken = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})


export class StagerService {

  constructor(private http: HttpClient) { 
  }

  _http: Http;
  resp: any;

  sendLicenseFile() { }
  
  /** POST: send FormData to the webservice*/
  postLicenseFile(license_file: FormData) {
    return this.http.post<ILicenseInfoModel>(AppConfigService.settings.licenseapi+"LicenseStager", license_file, httpOptions)
      .toPromise()
      .then(response => {
        let respond = JSON.stringify(response);
        return response;
      });

  }

  /** PUT: update LicenseInfoModel to the webservice*/
  putLicenseFile(license: ILicenseInfoModel) {
    return this.http.put<ILicenseInfoModel>(AppConfigService.settings.licenseapi+"LicenseStager", license, httpOptions)
      .toPromise()
      .then(response => {
        let respond = JSON.stringify(response);
        return response;
      });

  }

 /* postUserConfirmation(license: ILicenseInfoModel): Observable<ILicenseInfoModel> {
    return this.http.put<ILicenseInfoModel>(AppConfigService.settings.licenseapi+"LicenseStager", license, httpOpt).pipe(
      map(this.extractData)
    );
  }*/

  /*postUserConfirmation(license: ILicenseInfoModel): Observable<ILicenseInfoModel> {
    let apiURL = AppConfigService.settings.licenseapi+"LicenseStager";
    return this.http.get<ILicenseInfoModel>(apiURL)
  }*/

  postUserConfirmation(license: ILicenseInfoModel): Observable<any> {
    return this.http.put(AppConfigService.settings.licenseapi+"LicenseStager", license, this.httpOptions).pipe(
      tap(_ => console.log(`updated user confirmation`)),
      map(this.extractData)
    );
  }

  /* when "save chancges" button is pressed in the matdialog's transaction edit 
  window (dialogeditdetails.components.html) this method is called, it uses a calback fuction (cb) for 
  syncronizing the refresh on the screen once the update is finished on the back end. */

  putUpdateTransaction(transaction: ITransactionsService, cb: (resp: any) => any) {
    return this.http.put<ITransactionsService>(AppConfigService.settings.licenseapi+"LicenseStager/TransUpd", transaction, httpOptions)
      .subscribe(resp => {
        cb(resp);
      });
  }

  putUserConfirmation(license: ILicenseInfoModel): Observable<any> {
  return this.http.put(AppConfigService.settings.licenseapi+"LicenseStager", license, this.httpOptions).pipe(
    tap(_ => console.log(`updated user confirmation`)),
    map(this.extractData)
  );
}

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };


  /**
* Function to extract the data when the server return some
*
* @param res
*/
private extractData(res: Response) {
  let body = res;
  return body || {};
}

}



