import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITransactionsService } from '../Models/itransactions.service';
import { map, tap } from 'rxjs/operators';
import { AppConfigService } from '../configuration/app-config.service';
import { ILicenseInfoModel } from '../ILicenseInfoModel';

// Set the http options
const httpOptions = {
  headers: new HttpHeaders({})
};

@Injectable({
  providedIn: 'root'
})

export class LicenseComparisonPageService {
  constructor(private http: HttpClient) { }

  getLinceseInfo(transactionId: string): Observable<any> {
    return this.http.get<ITransactionsService>(AppConfigService.settings.licenseapi+"Transactions/"+transactionId+"/LicenseInfo", httpOptions).pipe(
      tap(_ => console.log(`fetched trasaction: ${transactionId}`),
      map(this.extractData)
    ));
  }
  
  compareLicenseInfo(license_file: FormData) {
    return this.http.post<ILicenseInfoModel>(AppConfigService.settings.licenseapi+"Compare", license_file, httpOptions)
    .toPromise()
    .then(response => {
      let respond = JSON.stringify(response);
      return response;
    });
  }

  /**
* Function to extract the data when the server return some
*
* @param res
*/
  private extractData(res: Response) {
    //console.log('Res: ' + res);
    const body = res;
    return body || {};
  }
}
