import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient} from '@angular/common/http';
import { AppConfigService } from './../../configuration/app-config.service';

// Set the http options
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json',
                            'Skip': 'true' })
  };

@Injectable({
  providedIn: 'root'
})

export class DialogeditdetailsService {


  constructor(private http: HttpClient) { }
 
  getLicenseContent(transactionId: string) {
  return this.http.get(AppConfigService.settings.licenseapi+"LicenseFiles/" + transactionId, {responseType: 'text'})
  }


}
