export interface ILicenseInfoModel {
  ID: string;
  //Priority: Priority;
  //Wo: string;
  StartWindow: string;
  EndWindow: string;
  StagingState: StagingState;
  Name: string; //submitter
  Vendor: string;
  //Comments: string;
  Contact: string;
  License: ILicense;
  Filename: string;
  TransactionId: string;
  ApiResponseCode: ApiCode;
}

export interface ILicense {
  DRServer: string;
  Name: string;
  StagingPath: string;
  Port: number;
  Server: string;
  ServerID: string;
  Daemons: IDaemons[];
  Apps: IApp[];
  Features: IComparedElement[];
  Packges: IComparedElement[];
}

export interface IApp{
  CartaId: string;
  AppName: string;
  AppId: number;
  Features: IComparedElement[];
}

export interface IDaemons {
  Name: string;
  Path: string;
  Opt: string;
  Port: string;

}
export interface IComparedElement {
  Name: string;
  Key1: string;
  Key2: string;
  AllContentOutcome: ComparisonOutcome;
  Version: number;
  DifferencesFound: IDifference[];
  AssemblyResult: AssemblyResult;
}

export interface IDifference {
  AttributeName: string;
  Value1: string;
  Value2: string;
  Comparission: number;
  ItemSelect: number;
}

export enum StagingState {

  Error,
  Unsupported,
  Unrecognized,
  Valid,
  PendingCRQ,
  Cancelled,
  Manual,
  Completed,
  New,
  Scheduled,
  WaitingForCRQApproval
}

export enum LMState {

  Automatic,
  ToBeAssigned,
  BeingWorked,
  Completed,
  Error
}
export enum Priority {
  Low,
  Medium,
  High
}

export enum ComparisonOutcome
{
  PresentOnA,
  PresentOnB,
  Equal,
  Different,
  PresentOnNeither
}


export enum Status {
  New = 'New',
  In_Progress = 'In_Progress',
  Cancelled = 'Cancelled',
  Completed = 'Completed'
}

export enum AssemblyResult {
  OnlyOldFound,
  OnlyOldFoundAndIsExpired,
  OnlyNewFound, //. Show to the user
  BothAreTheSame,
  BothWillBeAdded, // Show to the user
  NewReplacesOld, //. Show to the user
  NewReplacesOldWithLessSeats, //. Show to the user
  NewReplacesOldWithLessDate, //. Show to the user
  NewReplacesOldWithLessSeatsAndDate, //. Show to the user
  NewCantReplaceBecauseOfStartDate, //. Show to the user
  NoneWillBeAdded,
  NewExpired //. Show to the user
}

export enum ApiCode {
    Ok,
    WrongServerEnvironment,
    SameAppSameServerPending
}