import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthorizationService } from '../authorization/authorization.service';
import { Roles } from '../roles';
import { AuthService } from '../authentication/auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {
    constructor(private msalService: AuthService,
      private router: Router, private jwt: AuthorizationService) { }

      canActivate(){
          if (this.msalService.authenticated ) {
          console.log("user authenticated")
        return true;
    } else {
        console.log("user Notauthenticated")
        this.router.navigate(['/ErrorPage']);
        return false;
    }

  }
}
