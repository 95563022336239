import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from './authorization/authorization.service';
import { ILicenseInfoModel, StagingState } from './ILicenseInfoModel';
import { Roles } from './roles';
import { AuthService } from './authentication/auth-service.service';

@Injectable({
    providedIn: 'root'
})
export class LicenseService {
    license: ILicenseInfoModel;

    constructor(private router: Router, private jwt: AuthorizationService,
        private authService: AuthService) { }

    updateLicense(license: ILicenseInfoModel) {
        this.license = license;
        this.updateScreen();
    }

    updateLicenseWithoutReloading(license: ILicenseInfoModel) : ILicenseInfoModel {
        this.license = license;
        return this.license;
    }

    getLicense(): ILicenseInfoModel {
        return this.license;
    }

    updateScreen() {
        if (this.license.StagingState == StagingState.Unsupported) {
            this.router.navigate(['/NotUSMLicense']);
        } else if (this.license.StagingState == StagingState.Unrecognized) {
            this.router.navigate(['/LicenseNotRecognized']);
        } else if (this.license.StagingState == StagingState.Valid) {
            this.router.navigate(['/USMLicense']);
        }

        /*   Error,
            1 New_NoUSM,
            2 New_Unknown,
            3 New_Known,
            4 A_UserAgrees,
            5 B_UserDisagrees,
            C_USMMoreInfo,
            D_Cancel,
            USMReview,
            ReadyToInstall,
            Installed
        */
    }


}
