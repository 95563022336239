import { Component, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { LicenseComparisonPageService } from './licensecomparisonpage.service';
import { LicenseComparisonComponent } from '../license-comparison/licensecomparison.component';
import { ActivatedRoute } from '@angular/router';
import { ILicenseInfoModel } from '../ILicenseInfoModel';
import { MeasuresService } from '../measures.service';
import { MeasuresEvent } from 'src/dataModel/measuresEvent.model';


@Component({
    selector: 'app-license-comparison-page',
    templateUrl: './licensecomparisonpage.component.html',
    styleUrls: ['./licensecomparisonpage.component.css']
})

export class LicenseComparisonPageComponent implements OnInit{
    public isLoading: boolean;
    public license: ILicenseInfoModel;
    private transactionId: string;

    @ViewChild(LicenseComparisonComponent) licenseComparison: LicenseComparisonComponent;

    constructor(private cdRef: ChangeDetectorRef, private licenseComparisonPageService: LicenseComparisonPageService, private route: ActivatedRoute, private measuresService: MeasuresService) {
        this.isLoading = true;
        this.route.paramMap.subscribe(params => {
            this.transactionId = params.get('transactionId');
        });
     }
    
    ngOnInit(){
        this.getLincenseInfo(this.transactionId);
        this.measuresService.sendEvent(new MeasuresEvent('View License Info', {TransactionId: this.transactionId})).subscribe();
    }

    public getLincenseInfo(transactionId: string) {
        var self = this;
        this.licenseComparisonPageService.getLinceseInfo(transactionId)
            .subscribe(
                data => {
                    self.license = data;
                    self.isLoading = false;
                    self.cdRef.detectChanges();
                },
                error => {
                    console.error(error);
                });
    }

}
