import { Component, OnInit } from '@angular/core';

import { LicenseService } from '../license.service';
import { ILicenseInfoModel } from '../ILicenseInfoModel';
import { StagerService } from '../stager.service';

@Component({
  selector: 'app-progress-tracker',
  templateUrl: './progress-tracker.component.html',
  styleUrls: ['./progress-tracker.component.css']
})
export class ProgressTrackerComponent implements OnInit {
    public firstTime: boolean; 
    license: ILicenseInfoModel;
  //  licenseService: LicenseService;
    constructor(private stagerService: StagerService, private licenseService: LicenseService) { 
         
    }
   
    ngOnInit() {
        if (this.licenseService.license != null) {
            this.license = this.licenseService.getLicense();
            this.updateTracker();
        }
       this.firstTime = true;
    }
    
    public updateTracker() {
        
        let progress = this.license.StagingState;
        console.log(progress);
        if (progress == 2 || progress == 3) {
            this.setInProgress();
        } else if ( progress == 1 || progress == 4 || progress == 6) {
            this.setCompleted();
            this.firstTime = false;
        }
        if (this.firstTime == false) {
            this.initProgressBar();
        }
        /*   Error,
            1 New_NoUSM,
            2 New_Unknown,
            3 New_Known,
            4 A_UserAgrees,
            5 B_UserDisagrees,
            6 C_USMMoreInfo,
            7 D_Cancel,
            8 USMReview,
            9 ReadyToInstall,
            10 Installed
        */

    }


    public setUploadYouLicense() {
        console.log("set in setUploadYouLicense");

        let upload = document.getElementById('upload');
        let inprogress = document.getElementById('inprogress');
        let completed = document.getElementById('completed');

        upload.classList.add("active");
        upload.classList.remove("completed");

        inprogress.classList.remove("active");
        inprogress.classList.remove("completed");

        completed.classList.remove("active");
        completed.classList.remove("completed");

    }
    public setInProgress() {
        console.log("set in progress");
        let upload = document.getElementById('upload');
        let inprogress = document.getElementById('inprogress');
        let completed = document.getElementById('completed');

        upload.classList.remove("active");
        upload.classList.add("completed");

        inprogress.classList.add("active");
        inprogress.classList.remove("completed");

        completed.classList.remove("active");
        completed.classList.remove("completed");

    }
    public setCompleted() {
        console.log("set in completed");

        let upload = document.getElementById('upload');
        let inprogress = document.getElementById('inprogress');
        let completed = document.getElementById('completed');

        upload.classList.remove("active");
        upload.classList.add("completed");

        inprogress.classList.remove("active");
        inprogress.classList.add("completed");

        completed.classList.remove("active");
        completed.classList.add("completed");

    }

    public initProgressBar() {
        console.log("iniciando progBar");
        let upload = document.getElementById('upload');
        let inprogress = document.getElementById('inprogress');
        let completed = document.getElementById('completed');
        
        upload.classList.add("active");
        upload.classList.remove("completed");

        inprogress.classList.remove("active");
        inprogress.classList.remove("completed");

        completed.classList.remove("active");
        completed.classList.remove("completed");

    }






}
