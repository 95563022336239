import { Component, OnInit, ElementRef, ViewChild, HostListener, enableProdMode } from '@angular/core';
import { ILicenseInfoModel, StagingState, ApiCode, ComparisonOutcome } from '../ILicenseInfoModel';
import { LicenseFile } from '../LicenseFile';
import { AuthorizationService } from '../authorization/authorization.service';
import { AuthService } from '../authentication/auth-service.service';
import { LicenseComparisonPageService } from '../license-comparison-page/licensecomparisonpage.service';
import { LicenseService } from '../license.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ProgressTrackerComponent } from '../progress-tracker/progress-tracker.component';
import { CartaService } from '../cartaservice';
import { MeasuresService } from '../measures.service';
import { MeasuresEvent } from 'src/dataModel/measuresEvent.model';


enableProdMode();


@Component({
    selector: 'app-comparison',
    templateUrl: './comparison.component.html',
    styleUrls: ['./comparison.component.css']
})


export class ComparisonComponent implements OnInit {
    myForm: FormGroup;

    response: any;
    license: ILicenseInfoModel;
    isChecked: boolean = false;
    fileEmpty: boolean = true;
    nextClicked: boolean = false;
    maxCommentsChars: Number = 500;
    commentCount = '';
    chars = 0;

    @ViewChild('fileInput') fileInput: ElementRef;
    @ViewChild(ProgressTrackerComponent) progressTracker: ProgressTrackerComponent;

    ngOnInit() {
        this.measuresService.sendEvent(new MeasuresEvent('View Comparison')).subscribe();
    }
    onGoHome() {
    } 

    
    scrollToElement($element): void {
        setTimeout(() =>$element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"}));
      }

    constructor(private fb: FormBuilder, private comparisonService: LicenseComparisonPageService, private licenseService: LicenseService, private jwt: AuthorizationService,
        private authService: AuthService, private authorizationService: AuthorizationService, private cartaService: CartaService, private measuresService: MeasuresService) {
        this.createForm();
    }

    createForm() {
        this.myForm = this.fb.group({
            LicenseFile: null
        });
    }

    //Drag & Drop events

    onFileChange(event) {
        if (event.target.files.length > 0) {
            let file = event.target.files[0];
            this.myForm.get('LicenseFile').setValue(file);
            this.appendFileName_(file.name);
            document.getElementById('warning-message').classList.replace("message-visible", "message-hidden");
            this.fileEmpty = false;
        }
        if (event.target.files[0].name != "") {
            this.fileEmpty = false;
        }
    }

    getFileBoxStatus() {
        if (!this.fileEmpty) {
            return 'License file attached.';

        } else {
            return 'Only 1 license files is allowed.'
        }
    }

    allowDrop(ev) {
        ev.preventDefault();
    }

    drag(ev) {
        ev.preventDefault();
        ev.dataTransfer.setData("text", ev.target.id);
    }

    drop(ev) {
        document.getElementById('warning-message').classList.replace("message-visible", "message-hidden");
        ev.preventDefault();

        if (ev.dataTransfer != 'undefined') {
            console.log(ev.dataTransfer.files[0]);
            let file = ev.dataTransfer.files[0];

            this.appendFileName_(file.name);
            this.myForm.get('LicenseFile').setValue(file);
            this.fileEmpty = false;

        }
    }


    async onSubmit(model: LicenseFile) {
        //Check if a file was uploaded
        if (!model.LicenseFile) {
            document.getElementById('message-inner-text').innerHTML = ' A license file is required';
            document.getElementById('warning-message').classList.replace("message-hidden", "message-visible");
            return;
        }

        //Check if is an executable
        if (await isExecutable(model.LicenseFile)) {
            document.getElementById('message-inner-text').innerHTML = 'Executable files are not allowed';
            document.getElementById('warning-message').classList.replace("message-hidden", "message-visible");
            return;
        }

        this.measuresService.sendEvent(new MeasuresEvent('Compare license', {File: model.LicenseFile.name})).subscribe();

        //Carta service not needed
        this.cartaService.isAlive = true;

            this.nextClicked = true
        let loader_ = document.getElementById('loader');
        loader_.classList.remove("loader_hidden");

        let formData = this.prepareFormData(model);

        this.comparisonService.compareLicenseInfo(formData)
            .then(res => {
                this.license = res;
                loader_.classList.add("loader_hidden");
                this.license = this.licenseService.updateLicenseWithoutReloading(this.license);

                if (this.license.License == null || this.license.License.Apps.some(
                                                                                app => app.Features.some(
                                                                                feat => feat.AllContentOutcome != ComparisonOutcome.Equal) ) )
                {
                    if(this.license.ApiResponseCode === ApiCode.SameAppSameServerPending)
                    {
                        this.license.StagingState = StagingState.Cancelled;
                        this.licenseService.updateLicenseWithoutReloading(this.license);
                    }
                    if (this.license.License != null && this.license.License.DRServer != null){
                    }
                    this.licenseService.updateScreen();
                }
                else {
                    this.licenseService.updateScreen();
                }               
              
            })
    }

    prepareFormData(model: LicenseFile) {
        let formData = new FormData();

        formData.append('LicenseFile', model.LicenseFile, model.LicenseFile.name);
        formData.append('UserRole', this.authorizationService.getRole(this.authService.getCachedToken()));
        formData.append('Name', this.authService.getName());
        formData.append('Mail', this.authService.getMail());
        console.log("Prepare Form Data: " + this.authService.getName());
        return formData;
    }

    appendFileName_(name) {
        document.getElementById("li__filename").innerHTML = "File: " + name;
    }

  
    canDeactivate(): boolean {
        return this.fileEmpty || !this.nextClicked
    }

    @HostListener('window:beforeunload', ['$event'])
    unload($event: any) {
        if (!this.canDeactivate()) {
            $event.returnValue = true
        }
    }
}

async function isExecutable(file: File) {
    const InvalidSignatures = ['4d 5a', 'e8', 'e9', 'eb', '0', 'dc dc', '1d 7d', '4c 0 0 0 1 14 2 0', 'd0 cf 11 e0 a1 b1 1a e1',
        '52 45 47 45 44 49 54', 'ff fe', '57 69 6e 64 6f 77 73'];
    const Invalidextensions = ['.vb', '.vba', '.vbs', '.cmd', '.bat', '.wsc', '.ps1', '.wsf'];

    return new Promise<boolean>((resolve, reject) => {
        var reader = new FileReader();

        reader.onload = () => {
            let firstBytes = ascii_to_hexa(reader.result.toString().substring(0, 8).trim());
            var isExecutable = InvalidSignatures.some(sig => firstBytes.startsWith(sig));
            if (!isExecutable)
                isExecutable = Invalidextensions.some(ext => file.name.endsWith(ext));
            resolve(isExecutable);
        };
        reader.readAsText(file);
    });
}

function ascii_to_hexa(str: string) {
    var arr1 = [];
    for (var n = 0, l = str.length; n < l; n++) {
        var hex = Number(str.charCodeAt(n)).toString(16);
        arr1.push(hex);
    }
    return arr1.join(' ');
}

@Component({
    selector: 'snack-bar-component-example-snack',
    template: "<span class='snack-message'><i class='material-icons'>bubble_chart</i>" +
        " The license you´ve just submitted is identical to the one on the server</span>",
    styles: [`
      .snack-message {
        color: hotpink;
        display: table-cell;
        text-align: center;
      }
    `],
})
export class LicenseSnackBarComponent { }

@Component({
    selector: 'snack-bar-component-example-snack',
    template: "<span class='snack-message'><i class='material-icons'>bubble_chart</i>" +
        "Make sure you also submit the Dongle License for the Disaster Recovery server. </span>",
    styles: [`
      .snack-message {
        color: hotpink;
        display: table-cell;
        text-align: center;
      }
    `],
})
export class LicenseDongleSnackBarComponent { }

@Component({
    selector: 'snack-bar-component-example-snack',
    template: "<span class='snack-message'><i class='material-icons'>bubble_chart</i>" +
        " There is a pending installation for the same application and server. Please, try again later.</span>",
    styles: [`
      .snack-message {
        color: hotpink;
        display: table-cell;
        text-align: center;
      }
    `],
})
export class PendingLicenseSnackBarComponent { }