import { Component, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Idle } from '@ng-idle/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AuthService } from '../app/authentication/auth-service.service';
import { AppConfigService } from '../app/configuration/app-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  private setIdle = AppConfigService.settings.sessionIdle;
  private setTimeout = AppConfigService.settings.sessionTimeout;

  public modalRef: BsModalRef;

  //@ViewChild('childModal') childModal: ModalDirective;

  constructor(myElement: ElementRef, private titleService: Title, private idle: Idle, private keepalive: Keepalive, private router: Router, private modalService: BsModalService, private authService: AuthService) { 

    this.titleService.setTitle("License Automation Tool");

    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(this.setIdle);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(this.setTimeout);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      this.authService.logout();
      //this.router.navigate(['/']);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState);
      //this.childModal.show();
    });
    /*
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      console.log(this.idleState);
    });*/

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
  /*
  stay() {
    this.childModal.hide();
    this.reset();
  }*/
  /*
  logout() {
    this.childModal.hide();
    this.authService.logout();
    //this.appService.setUserLoggedIn(false);
    //this.router.navigate(['/']);
  }*/

  isInternetExplorer(): boolean {
    return this.getBrowser().startsWith('IE');
  }

  getBrowser(): string {
    var ua = navigator.userAgent, tem,
      M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge?)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera').replace('Edg ', 'Edge ');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  }

  isMainPage(){
    return this.router.url == "/main"
  }
}
