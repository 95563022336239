import { Component, OnInit } from '@angular/core';
import { LicenseService } from '../license.service';
import { ILicenseInfoModel } from '../ILicenseInfoModel';
import { Router } from '@angular/router';


@Component({
  selector: 'app-notusmlicense',
  templateUrl: './notusmlicense.component.html',
  styleUrls: ['./notusmlicense.component.css']
})
export class NotusmlicenseComponent implements OnInit {

  license: ILicenseInfoModel;

  constructor(private licenseService: LicenseService, private router: Router) { }

  ngOnInit() {
    this.license = this.licenseService.getLicense();
  }

  onBtnClicked(){
    this.router.navigate(['/main']);
  }
}
