import { StagerService } from './../../stager.service';
import { LicenseService } from './../../license.service';
import { Status } from './../../ILicenseInfoModel';
import { ITransactionsService } from './../../Models/itransactions.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { DialogeditdetailsService } from './../dialogviewdetails/dialogeditdetails.service';
import { AppConfigService } from './../../configuration/app-config.service';
import * as FileSaver from 'file-saver';
import { MeasuresService } from 'src/app/measures.service';
import { MeasuresEvent } from 'src/dataModel/measuresEvent.model';


@Component({
  selector: 'app-dialogeditdetails',
  templateUrl: './dialogeditdetails.component.html'

})

export class DialogeditdetailsComponent  {

  keys = Object.keys;

  symbols = Status;

  numberOfItems = Object.keys(this.symbols);
  lmselectedstatus: Status;
  updatedticket: string;
  alert: boolean;
  licenseData: string;
  public onClickProcess: boolean = false;
  public isSaving: boolean = false;
  public dateS: string;
  fileToSave: string;
  toRemove:string;
  

  constructor(public dialogRef: MatDialogRef<DialogeditdetailsComponent>, private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public transaction: ITransactionsService, private dialogeditdetailsService: DialogeditdetailsService,
    private stagerservice: StagerService, private snackBar: MatSnackBar, private licenseService: LicenseService, private measuresService: MeasuresService) {

    /* constructor must inicialize transaction object accessing each subvalue of the first item in the object array, don't know why 
      but the object that is injected via MAT_DIALOG_DATA cant be accessed at once like other objects.*/

    this.transaction.transactionId = this.transaction['transaction']['transactionId'];
    this.transaction.appId = this.transaction['transaction']['appId'];
    this.transaction.ticket = this.transaction['transaction']['ticket'];
    this.transaction.submittedBy = this.transaction['transaction']['submittedBy'];
    this.transaction.submittedDate = this.transaction['transaction']['submittedDate'];
    this.transaction.status = this.transaction['transaction']['status'];
    this.transaction.lmstatus = this.transaction['transaction']['lmstatus'];
    this.transaction.attachment = this.transaction['transaction']['attachment'];
    this.transaction.guid = this.transaction['transaction']['guid'];
    this.transaction.startWindow = this.transaction['transaction']['startWindow'];
    this.transaction.endWindow = this.transaction['transaction']['endWindow'];
    this.transaction.completed = this.transaction['transaction']['completed'];
    this.transaction.comments = this.transaction['transaction']['comments'];
    this.transaction.submitterMail = this.transaction['transaction']['submitterMail'];
    this.transaction.hostId = this.transaction['transaction']['hostId'];
    this.transaction.licenseType = this.transaction['transaction']['licenseType'];

  }
  UpdateTransaction(transaction: ITransactionsService) {
    this.isSaving = true;
    this.updtransact(transaction);
  }

  updtransact(transaction) {
    this.stagerservice.putUpdateTransaction(transaction, (resp: any) => {
      //Update changed fields in the parent container to not have to reload the page and not to remove filters if it has
      this.transaction['transaction']['ticket'] = this.transaction.ticket
      this.transaction['transaction']['lmstatus'] = this.transaction.lmstatus
      //If more fields later can be modified add them here
      this.isSaving = false;
      this.dialogRef.close()
      this.openSnackBar()
    });
  }

  CloseWindow() {
      this.dialogRef.close()
  }

  
  getLicenseFile()  {
      var dates=new String(this.transaction.submittedDate.slice(5, 7).concat(this.transaction.submittedDate.slice(8, 10))
      .concat(this.transaction.submittedDate.slice(0, 4)));
      var fileName=this.transaction.attachment
      var lastDotIndex = fileName.lastIndexOf(".");

      if (lastDotIndex == -1) { var extFile = ""; var nameFile = fileName }
      else { var extFile = fileName.slice(lastDotIndex+1); var nameFile = fileName.slice(0,lastDotIndex); }

      if(!extFile) { extFile="" } else { extFile="."+extFile }

      this.dateS=dates.toString();

      if(nameFile.search(this.dateS) == -1) { this.fileToSave=nameFile.concat(extFile); }
      else {
          this.toRemove=nameFile.slice(-14);
          this.fileToSave=nameFile.replace(this.toRemove,"").concat(extFile);
      }

      this.measuresService.sendEvent(new MeasuresEvent('Download license', {File: this.fileToSave})).subscribe();
 
      this.onClickProcess = true;
      this.dialogeditdetailsService.getLicenseContent(this.transaction.transactionId).subscribe(data => {
      this.licenseData = data;
      this.downloadFile(this.licenseData);
    })

  }
 

  downloadFile( data: string) {
    this.onClickProcess = false;
    const blob = new Blob([data], { type: '/plain;charset=utf-8' });
    FileSaver.saveAs(blob, this.fileToSave)

    this.openFileDownloadSnackBar();
  }
  
  openSnackBar() {
    this.snackBar.openFromComponent(SuccesfulEditSnackBarComponent, {
        duration: 4 * 1000
    });
  }

  openFileDownloadSnackBar() {
    this.snackBar.openFromComponent(SuccesfullFileDownload, {
        duration: 4 * 1000
    });
  }
 
}

//Snackbar
@Component({
  selector: 'snack-bar-component-saveedition-snack',
  template: "<span class='snack-message'>&nbsp;&nbsp;" + 
            " The transaction was succesfully updated</span>",
  styles: [`
    .snack-message {
      color: hotpink;
      display: table-cell;
      text-align: center;
    }
  `],
})
export class SuccesfulEditSnackBarComponent {};

@Component({
  selector: 'snack-bar-component-downloadfile-snack',
  template: "<span class='snackdownload-message'>&nbsp;&nbsp;"+
            " License file was succesfully downloaded</span>",
  styles: [`
    .snackdownload-message {
      color: hotpink;
      display: table-cell;
      text-align: center;
    }
  `],
})
export class SuccesfullFileDownload {};