import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MainComponent, LicenseSnackBarComponent, LicenseDongleSnackBarComponent, PendingLicenseSnackBarComponent } from './main/main.component';

import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';

import { UsmlicenseComponent } from './usmlicense/usmlicense.component';

import { LicenseComparisonComponent } from './license-comparison/licensecomparison.component';
import { LicenseComparisonPageComponent } from './license-comparison-page/licensecomparisonpage.component';

import { NotusmlicenseComponent } from './notusmlicense/notusmlicense.component';
import { LicensenotrecognizedComponent } from './licensenotrecognized/licensenotrecognized.component';
import { AppRoutingModule } from './app-routing.module';
import { ProgressTrackerComponent } from './progress-tracker/progress-tracker.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProcessCompletedComponent } from './process-completed/process-completed.component'
import { ErrorPageComponent } from './error-page/error-page.component';

import { environment } from '../environments/environment';
import { MsalModule, MsalInterceptor, MsalGuard } from '@azure/msal-angular';

import { AuthorizationService } from './authorization/authorization.service';
import { AuthService } from './authentication/auth-service.service';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AdmingridComponent } from './admin-grid/admingrid.component';
import { DialogviewdetailsComponent } from './admin-grid/dialogviewdetails/dialogviewdetails.component';
import { DialogeditdetailsComponent, SuccesfulEditSnackBarComponent, SuccesfullFileDownload } from './admin-grid/dialogviewdetails/dialogeditdetails.component';
import { MatDialogModule, MatSortModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { AppConfigService } from './configuration/app-config.service';
import { MatFormFieldModule, MatInputModule, MatSelectModule } from '@angular/material';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { InternetCompatibilityMsgComponent } from './internet-compatibility-msg/internet-compatibility-msg.component';
import { CanExitWithoutSavingGuard } from './can-exit-without-saving.guard';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CartaService } from './cartaservice';
import { MatTooltipModule } from '@angular/material';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
// import this in app.module.ts
import { ModalModule } from 'ngx-bootstrap/modal';
import { ContactComponent } from './contact/contact.component';
import { ComparisonComponent } from './comparison/comparison.component';
import { MatCardModule } from '@angular/material/card';
import { MeasuresService } from './measures.service';

export function tokenGetter() {
    return localStorage.getItem('access_token');
}

export function initializeApp(appConfig: AppConfigService) {
    return () => appConfig.configInit();
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        MainComponent,
        UsmlicenseComponent,
        LicenseComparisonComponent,
        LicenseComparisonPageComponent,
        NotusmlicenseComponent,
        LicensenotrecognizedComponent,
        ProgressTrackerComponent,
        ProcessCompletedComponent,
        ErrorPageComponent,
        AuthCallbackComponent,
        AdmingridComponent,
        DialogviewdetailsComponent,
        DialogeditdetailsComponent,
        LicenseSnackBarComponent,
        LicenseDongleSnackBarComponent,
        PendingLicenseSnackBarComponent,
        SuccesfulEditSnackBarComponent,
        SuccesfullFileDownload,
        InternetCompatibilityMsgComponent,
        ContactComponent,
        ComparisonComponent,
    ],
    exports: [
        MatFormFieldModule,
        MatInputModule,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpModule,
        HttpClientModule,
        FormsModule,
        AmazingTimePickerModule,
        MatSlideToggleModule,
        MatSortModule,
        MatInputModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MsalModule.forRoot(getMsalConfig()),
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                whitelistedDomains: ['stager.azurewebsites.net']
                //blacklistedRoutes: ['example.com/examplebadroute/']
            }
        }),
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatDialogModule,
        MatTooltipModule,
        MatSnackBarModule,
        [
            NgIdleKeepaliveModule.forRoot(),
        ],
        ModalModule.forRoot(),
        FormsModule,
        MatCardModule
    ],
    providers: [
        Title,
        MsalModule,
        MsalGuard,
        AuthorizationService,
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfigService],
            multi: true
        },
        CanExitWithoutSavingGuard,
        CartaService,
        MeasuresService,
    ],
    entryComponents: [DialogviewdetailsComponent, DialogeditdetailsComponent, LicenseSnackBarComponent, LicenseDongleSnackBarComponent, PendingLicenseSnackBarComponent, SuccesfulEditSnackBarComponent, SuccesfullFileDownload],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function getMsalConfig() {
    return environment.msalconfig;
}
