import { Component, OnInit } from '@angular/core';
import { AuthService } from '../authentication/auth-service.service';
import { AuthorizationService } from '../authorization/authorization.service';
import { Roles } from '../roles';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']

})

export class HeaderComponent implements OnInit {
  activeuser: any;
  isAdmin: boolean;
  authenticated: boolean;
  
  
  constructor(private authService: AuthService, private jwt: AuthorizationService) {   
  }
 
  ngOnInit() {
      this.activeuser = this.authService.getName();
     
      this.authenticated = this.authService.authenticated;    
      
      if (Roles.LMSupport === this.jwt.getRole(this.authService.getCachedToken())) {
        this.isAdmin = true;
      }
    }
    logout() {
      this.authService.logout();
    }
}

