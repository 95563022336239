import { timer } from 'rxjs';
import { Component, OnInit, EventEmitter, Output, NgModule, enableProdMode, ViewChild} from '@angular/core';
import { MatDialog, MatInputModule, MatDialogConfig} from '@angular/material';
import { MatButtonModule,MatIconModule, MatFormFieldModule} from '@angular/material';
import { Sort, SortDirection, MatSort } from '@angular/material/sort';
import { ITransactionsService } from "../Models/itransactions.service";
import { Injectable } from '@angular/core';
import { AdmingridService } from './admingrid.service';
import { PagerService } from '../pager/pager-service.service';
import { DialogeditdetailsComponent } from './dialogviewdetails/dialogeditdetails.component';
import { DialogviewdetailsComponent } from './dialogviewdetails/dialogviewdetails.component';
import { AuthService } from '../authentication/auth-service.service'
import { AuthorizationService } from '../authorization/authorization.service';
import { StagingState } from '../ILicenseInfoModel';
import { environment } from 'src/environments/environment';
import { AppConfigService } from '../configuration/app-config.service';
import { MeasuresService } from '../measures.service';
import { MeasuresEvent } from 'src/dataModel/measuresEvent.model';


enableProdMode();

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-admingrid',
  templateUrl: './admingrid.component.html',
  styleUrls: ['./admingrid.component.css']
})

@NgModule({
  imports: [
    MatSort,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
  ]
})

export class AdmingridComponent implements OnInit {
  @Output() OpenModalEvent = new EventEmitter();

  public transactions: ITransactionsService[] = [];
  public transaction: ITransactionsService;
  hide = true;
  public activeuser: any;
  public isUser: boolean;
  public authenticated: boolean;

  // pager object
  pager: any = {};

  // paged items
  pagedItems: any[];

  // Sorted grid
  public sortedData: ITransactionsService[] = [];
  public showFilter: boolean;
  public noTransToshow: boolean;
  public cleanFilterOn: boolean;
  public installWindowsTooltip: String;
  // Filter applied
  public filters: ITransactionsService;

  // tslint:disable-next-line: max-line-length
  constructor(public dialog: MatDialog, private admingridService: AdmingridService, private pagerService: PagerService, private authService: AuthService, private jwt: AuthorizationService,
    private measuresService: MeasuresService
    ) {
    }

    getCrqRedirectionUrl(ticket: string,sys_id:string) {
        if (ticket.startsWith("CRQ")) {
            return AppConfigService.settings.crqITSMRedirectionURl + ticket
        }
        else if(ticket.startsWith("CHG")) {
          return AppConfigService.settings.woRedirectionUrl + sys_id
        }
        else{
          return AppConfigService.settings.crqRedirectionUrl + sys_id
        }
        
    }

   ngOnInit() {
    this.showFilter = false;
    this.cleanFilterOn =false;
    this.filters = new ITransactionsService;
    this.installWindowsTooltip ="";
    this.noTransToshow = false;
    this.isUser = false;

    //validate role if user or LMSupport
    this.activeuser = this.authService.getName();
     
    this.authenticated = this.authService.authenticated;    
    if(this.jwt.getRole(this.authService.getCachedToken()) == 'User' ){
      this.isUser = true;
    }

    this.measuresService.sendEvent(new MeasuresEvent('View History', {isLMSupport: !this.isUser})).subscribe();

    this.admingridService.getTrans().subscribe(async data => {
      this.transactions = data;
      this.transactions = this.transactions.sort((a, b) => {

      return compare_date(a.submittedDate, b.submittedDate, false);
      });

      if(this.isUser){
        this.transactions = this.transactions.filter(x => x.lmstatus != "UDR");
      }

      this.sortedData = this.transactions;


      //document.getElementById("loader").remove();
      if(this.transactions.length==0) 
        this.noTransToshow = true;
        this.defaultSort();
        await Promise.all([this.checkGrid()]);
    },

    error => {
    });

    cleanFilter(this.filters);
    this.doFilter();
  }
  
 public async checkGrid(){ 
    var beftarget=document.getElementById("gridtrans");
    await timer(500).toPromise();
    var target=document.getElementById("gridtrans");

    if(!target && !beftarget){
      window.location.reload();
      document.getElementById("gridtrans").focus();
    } else {
      document.getElementById("loader").remove();
      document.getElementById("gridtrans").focus();
   }
  }

  openDialogDetails(transaction: ITransactionsService) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = { transaction };

    this.dialog.open(DialogviewdetailsComponent, dialogConfig);
  }

  openDialogDetailsedit(transaction: ITransactionsService) {

    const dialogRef = this.dialog.open(DialogeditdetailsComponent, {

      data: { transaction },

    });
  }

  setPage(page: number) {
   
    const pagSize : number = 8;
    // get pager object from service
    this.pager = this.pagerService.getPager(this.sortedData.length, page, pagSize);
    this.pager.pages.length = pagSize;

    // get current page of items
    this.pagedItems = this.sortedData.slice(this.pager.startIndex, this.pager.endIndex + 1);

    // Hide loader when there's any transaction to show
    // if(this.transactions.length>=0)
    //   document.getElementById('loader').classList.add("loader_hidden");
  }

  filterBtnClick() {
    this.showFilter = !this.showFilter;

    if (this.showFilter) {
      document.getElementById('filter-container').classList.replace('filter-container-hidden', 'filter-container-visible');
      document.getElementById('filter-button').classList.replace('filter-button-low', 'filter-button-high');
      
    }
    else {
      document.getElementById('filter-container').classList.replace('filter-container-visible', 'filter-container-hidden');
      document.getElementById('filter-button').classList.replace('filter-button-high', 'filter-button-low');
      cleanFilter(this.filters);
      this.doFilter();
      this.defaultSort()

    }
    
  }

  public statusToDisplay(status: string, lmStatus: string) {
    var statusToCompare = StagingState [StagingState.Manual].toLowerCase();
    var statusToCompareUnrecognized = StagingState [StagingState.Unrecognized].toLowerCase();

    if (status.toLowerCase() == statusToCompareUnrecognized && this.isUser == true)
      return lmStatus
    
    return (status.toLowerCase() == statusToCompare && this.isUser == true)? lmStatus : status; 
  }
  public doFilterStatus(f: string) {
    this.filters.status = (f!=undefined)? f.toLowerCase() : "";
    this.doFilter();
    this.defaultSort();
  }
  public doFilterAttachment(f: string) {
    this.filters.attachment = (f!=undefined)? f.toLowerCase() : "";
    this.doFilter();
    this.defaultSort();
  }
  public doFilterTicket(f: string) {
    console.log(f);
    this.filters.ticket = (f!=undefined)? f.toLowerCase() : "";
    this.doFilter();
    this.defaultSort();
  }
  public doFilterSubmitter(f: string) {
    this.filters.submittedBy = (f!=undefined)? f.toLowerCase() : "";
    this.doFilter();
    this.defaultSort();
  }
  public doFilterDate(f: string) {
    this.filters.submittedDate = (f!=undefined)? f.toLowerCase() : "";
    this.doFilter();
    this.defaultSort();
  }
  public doFilterLMStatus(f: string) {
    this.filters.lmstatus = (f!=undefined)? f.toLowerCase() : "";
    this.doFilter();
    this.defaultSort();
  }

  public doFilter() {
    this.sortedData = this.transactions.slice();
    
    if(this.filters.status == undefined)  
      this.filters.status = "";
    if(this.filters.attachment == undefined)
      this.filters.attachment = "";
    if(this.filters.ticket == undefined)
      this.filters.ticket = "";
    if(this.filters.submittedBy == undefined)
      this.filters.submittedBy = "";
    if(this.filters.submittedDate == undefined)
      this.filters.submittedDate = "";
    if(this.filters.lmstatus == undefined)
      this.filters.lmstatus = "";
   
    
    this.sortedData = this.sortedData.filter( (a) => {
 
      if( a==null || (!a.status && !a.attachment && !a.ticket && !a.submittedBy && !a.submittedDate && !a.lmstatus) )
        return false;

      if( this.filters.status.length )
        if( a.status==undefined || !this.statusToDisplay(a.status, a.lmstatus).toLowerCase().includes(this.filters.status))
          return false;

      if( this.filters.attachment.length )
        if( a.attachment==undefined || !a.attachment.toLowerCase().includes(this.filters.attachment))
          return false;
      
      if( this.filters.ticket.length)
        if( a.ticket==undefined || !a.ticket.toLowerCase().includes(this.filters.ticket))
          return false;                      

      if( this.filters.submittedBy.length )
        if( a.submittedBy==undefined || !a.submittedBy.toLowerCase().includes(this.filters.submittedBy))
          return false;

      if( this.filters.submittedDate.length )
        if( a.submittedDate==undefined || !a.submittedDate.toLowerCase().includes(this.filters.submittedDate))
          return false;

      if( this.filters.lmstatus.length )
        if( a.lmstatus==undefined || !a.lmstatus.toLowerCase().includes(this.filters.lmstatus))
          return false;
  
      return true;
    });

    this.setPage(1);
  }

  public defaultSort()
  {
 
    this.sortedData = this.sortedData.sort((a, b) => {
 
        if(a.submittedDate && b.submittedDate)
        return compare_date(a.submittedDate, b.submittedDate, false);
    });
  
    this.setPage(1);
  }

  sortData(sort: Sort) 
  {
    if (!sort.active || sort.direction === '') {
      this.sortedData = this.transactions.slice();;
      return;
    }

    this.sortedData = this.sortedData.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'sort_status': return compare(a.status, b.status, isAsc);
        case 'sort_attachment': return compare(a.attachment, b.attachment, isAsc);
        case 'sort_ticket': return compare(a.ticket, b.ticket, isAsc);
        case 'sort_submitter': return compare(a.submittedBy, b.submittedBy, isAsc);
        case 'sort_date': console.log("Sorting by date " + sort.direction ); return compare_date(a.submittedDate, b.submittedDate, isAsc);
        case 'sort_lm_status': return compare(a.lmstatus, b.lmstatus, isAsc);
        default: return 0;
      }
    });

    this.setPage(1);
  }

}

function compare(a: string, b: string, isAsc: boolean) {
  var c_a = a == null || a == '' ? 'zzzzzzzzzzzzzzza' : a;
  var c_b = b == null || b == '' ? 'zzzzzzzzzzzzzzzb' : b;
  return (c_a < c_b ? -1 : 1) * (isAsc ? 1 : -1);
}

function compare_date(a: string, b: string, isAsc: boolean) {
  var date_a = Date.parse(a);
  var date_b = Date.parse(b);

  if (date_a == null && date_b == null)
    return compare(a, b, isAsc);
  else
    return (date_a.valueOf() < date_b.valueOf() ? -1 : 1) * (isAsc ? 1 : -1);
}

function cleanFilter(filters: ITransactionsService)
{
  let max = document.getElementsByClassName('filter-input').length;

  for(let i=0; i<max; i++)
  {  
    document.querySelectorAll('.filter-input')[Number(i)].nodeValue = "";
  }
  
  filters.attachment = "";
  filters.lmstatus = "";
  filters.status = "";
  filters.submittedBy = "";
  filters.submittedDate = "";
  filters.ticket = "";
  filters.transactionId = "";  
}