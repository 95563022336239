import { Component, OnInit } from '@angular/core';
import { AuthService } from '../authentication/auth-service.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  userName: string;

  constructor(private authService: AuthService) {
    this.userName = this.authService.getName().split(',')[1]
  }

  ngOnInit() {
  }

}
