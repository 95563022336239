import { Component, OnInit, HostListener } from '@angular/core';
import { ILicenseInfoModel, StagingState, ApiCode } from '../ILicenseInfoModel';
import { StagerService } from '../stager.service';
import { LicenseService } from '../license.service';
import { ComponentCanBeDeactivated } from '../can-exit-without-saving.guard';
import { Location } from '@angular/common';
import { CartaService } from '../cartaservice';
import { MeasuresService } from '../measures.service';
import { MeasuresEvent } from 'src/dataModel/measuresEvent.model';


@Component({
    selector: 'app-usmlicense',
    templateUrl: './usmlicense.component.html',
    styleUrls: ['./usmlicense.component.css']
})

export class UsmlicenseComponent implements OnInit, ComponentCanBeDeactivated {
    toggle_value: string;
    licenseName: string;
    licenseServer: string;
    licenseVendor: string;
    licenseStagingPath: string;
    licensePort: number;
    licenseServerID: string;
    licenseDRServer: string;
    licenseComparison: boolean = false;
    showSuccessModal: boolean = false;
    showErrorModal: boolean = false;
    isCartaAlive: boolean = false;
    isCartaEditable: boolean = false;
    searchFilter: string;
    public nextBtnDisabled: boolean;
    public nextBtnAlreadyClicked: boolean;
    public isLoading: boolean = true;
    license: ILicenseInfoModel;
    approvedLicenseMessage: string = "";

    constructor(private stagerService: StagerService,
        private licenseService: LicenseService,
        private _location: Location,
        private cartaService: CartaService,
        private measuresService: MeasuresService) { }

    ngOnInit() {
        this.license = this.licenseService.getLicense();
        this.licenseName = this.license.Name;
        this.licenseServer = this.license.License.Server;
        this.licenseVendor = this.license.Vendor;
        this.licenseServerID = this.license.License.ServerID;
        this.licenseDRServer = this.license.License.DRServer;
        this.licenseStagingPath = this.license.License.StagingPath;
        this.licensePort = this.license.License.Port;
        this.searchFilter = 'Search';
        this.nextBtnDisabled = true;
        this.nextBtnAlreadyClicked = false;
        this.isCartaEditable = this.isApiCodeOk();
        if (this.license.TransactionId == '-1') {
           this.licenseComparison = true;
        } 
        if (this.isCartaEditable) this.checkCartaAlive();
        if (this.isApiCodeWrongServerEnvironment()) this.isLoading = false;
    }

    onYesClicked() {
        if (this.nextBtnAlreadyClicked)
            return;

        this.nextBtnDisabled = false;
        this.showSuccessModal = true;
        this.showErrorModal = false;
        document.getElementById('btn-next').classList.replace('next-btn-disabled', 'next-btn-enabled');
    }

    onNoClicked() {
        this.nextBtnDisabled = false;
        this.showSuccessModal = false;
        this.showErrorModal = true;
        document.getElementById('btn-next').classList.replace('next-btn-disabled', 'next-btn-enabled');
    }

    onModalSuccessClicked() {
        this.nextBtnDisabled = true;
        this.nextBtnAlreadyClicked = true;
        document.getElementById('btn-next').classList.replace('next-btn-enabled', 'next-btn-disabled');
        document.getElementById('div-toggle').classList.add('toggle-btn-disabled');
    }

    onNext() {
        if (this.nextBtnDisabled || this.nextBtnAlreadyClicked)
            return;

        this.nextBtnAlreadyClicked = true;
        this.license.Name = this.licenseName;
        this.license.License.Server = this.licenseServer;
        this.license.Vendor = this.licenseVendor;

        if (this.toggle_value == 'Yes') {
            this.license.StagingState = StagingState.PendingCRQ;
            this.showSuccessModal = true;
            this.showErrorModal = false;

            this.measuresService.sendEvent(new MeasuresEvent('License submitted confirmed', {File: this.license.Filename})).subscribe();
        } else {
            this.license.StagingState = StagingState.Cancelled;
            this.showErrorModal = true;
            this.showSuccessModal = false;

            this.measuresService.sendEvent(new MeasuresEvent('License submitted cancelled', {File: this.license.Filename})).subscribe();
        }

        this.isLoading = true;
        this.isCartaEditable = false;
        if (!this.licenseComparison) {
            this.stagerService.putUserConfirmation(this.license).subscribe(
                _ => {
                    this.licenseService.updateLicenseWithoutReloading(this.license);
                    this.isLoading = false;
                    this.showModal()
                },
                error => {
                    console.error(error)
                });
        }    
    }

    showModal(){
        if(this.license.License.Server != "UPSLIC98")
            this.approvedLicenseMessage += "Once approved, t"
        else
            this.approvedLicenseMessage += "T"

        this.approvedLicenseMessage += `he license ${this.license.Filename} from ${this.license.Vendor} will be installed on the ${this.license.License.Server} server`;

        if (this.license.StartWindow && this.license.EndWindow)
            this.approvedLicenseMessage += " in the hours between " + this.license.StartWindow.toString() +
                " to " + this.license.EndWindow.toString();

        this.approvedLicenseMessage += ".";

        if (this.showSuccessModal)
            document.getElementById('success-modal-container').classList.replace('success-modal-hidden', 'success-modal-visible');
        else
            document.getElementById('success-modal-container').classList.replace('success-modal-visible', 'success-modal-hidden');

        if (this.showErrorModal)
            document.getElementById('error-modal-container').classList.replace('error-modal-hidden', 'error-modal-visible');
        else
            document.getElementById('error-modal-container').classList.replace('error-modal-visible', 'error-modal-hidden');
    }

    onBack() {
        this._location.back();
    }

    canDeactivate(): boolean {
        return this.nextBtnAlreadyClicked;
    }

    performTaskForDeactivation() {
        this.license.StagingState = StagingState.Cancelled;
        if (!this.licenseComparison) {
            this.stagerService.putUserConfirmation(this.license).subscribe(
                data => {
                    this.licenseService.updateLicense(this.license)
                },
                error => {
                    console.error(error)
                })
        }
    }

    checkCartaAlive() {
        var self = this;
        this.cartaService.isCartaAlive()
            .subscribe(
                () => {
                    self.isCartaAlive = true;
                    self.isLoading = false;
                },
                (err: any) => {
                    console.error(err);
                    self.isCartaAlive = false;
                    self.isLoading = false;
                }
            );
    }

    isApiCodeOk() {
        return this.license.ApiResponseCode === ApiCode.Ok;
    }

    isApiCodeWrongServerEnvironment() {
        return this.license.ApiResponseCode === ApiCode.WrongServerEnvironment;
    }

    @HostListener('window:beforeunload', ['$event'])
    unload($event: any) {
        if (!this.canDeactivate())
            $event.returnValue = true
    }
}
