import { Injectable } from '@angular/core';
import { IAppConfig } from './iapp-config.model';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import fetch from 'cross-fetch';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  static settings: IAppConfig;
  public appSettings: IAppConfig;
  constructor() { }

public configInit() {
  return from(
      fetch('assets/appsettings.json').then(function(response) {
        return response.json();
      })
    ).pipe(
      map((config) => {
      AppConfigService.settings = config;
      return 
    })).toPromise();
}

}