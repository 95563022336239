import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { LicensenotrecognizedComponent } from './licensenotrecognized/licensenotrecognized.component';
import { UsmlicenseComponent } from './usmlicense/usmlicense.component';
import { LicenseComparisonPageComponent } from './license-comparison-page/licensecomparisonpage.component';

import { NotusmlicenseComponent } from './notusmlicense/notusmlicense.component';
import { MainComponent } from './main/main.component';
import { ProcessCompletedComponent } from './process-completed/process-completed.component'
import { ErrorPageComponent } from './error-page/error-page.component'
import { RouteGuard } from './authorization/routeguard.guard';
import { AuthGuard } from './authentication/auth.guard';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { HeaderComponent } from './header/header.component';
import { AdmingridComponent } from './admin-grid/admingrid.component';
import { ProgressTrackerComponent } from './progress-tracker/progress-tracker.component';
import { CanExitWithoutSavingGuard } from './can-exit-without-saving.guard';
import { ComparisonComponent } from './comparison/comparison.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  { path: 'main', component: MainComponent, canActivate: [AuthGuard]},
  { path: 'header', component: HeaderComponent, canActivate: [AuthGuard] },
  { path: 'USMLicense', component: UsmlicenseComponent, canActivate: [RouteGuard], canDeactivate: [CanExitWithoutSavingGuard]},
  { path: 'A_UserAgrees', component: ProcessCompletedComponent },
  { path: 'B_UserDisagrees', component: LicensenotrecognizedComponent },
  { path: 'NotUSMLicense', component: NotusmlicenseComponent },
  { path: 'LicenseNotRecognized', component: LicensenotrecognizedComponent, canDeactivate: [CanExitWithoutSavingGuard] },
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: 'Error', component: ErrorPageComponent },
  { path: 'admingrid', component: AdmingridComponent , canActivate: [AuthGuard] },
  { path: 'Transactions/:transactionId/LicenseInfo', component: LicenseComparisonPageComponent , canActivate: [AuthGuard]},
  { path: 'progress-tracker', component: ProgressTrackerComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'comparison', component: ComparisonComponent, canActivate: [AuthGuard] },
  { path: '**', component: ErrorPageComponent }
];
export function isInIframe() {
    return window !== window.parent && !window.opener;
}
@NgModule({
  imports: [
    CommonModule,
        RouterModule.forRoot(routes, {
            enableTracing: false,
            useHash: false,
            initialNavigation: isInIframe() ? 'disabled' : undefined 
        })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
