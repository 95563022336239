import { Component, OnInit } from '@angular/core';
import { ILicenseInfoModel } from '../ILicenseInfoModel';

import { LicenseService } from '../license.service';
import { StagerService } from '../stager.service'
@Component({
  selector: 'app-process-completed',
  templateUrl: './process-completed.component.html',
  styleUrls: ['./process-completed.component.css']
})
export class ProcessCompletedComponent implements OnInit {

  license: ILicenseInfoModel;
    constructor(private stagerService: StagerService, private licenseService: LicenseService) { }

    ngOnInit() {
        this.license = this.licenseService.getLicense();
  }

}
