import { ITransactionsService } from './../../Models/itransactions.service';
import { Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { AuthService } from './../../authentication/auth-service.service'
import { AuthorizationService } from './../../authorization/authorization.service';


@Component({
  selector: 'app-dialogviewdetails',
  templateUrl: './dialogviewdetails.component.html'

})

export class DialogviewdetailsComponent {
  public activeuser: any;
  public isUser: boolean;
  public authenticated: boolean;  
constructor( public dialogRef: MatDialogRef<DialogviewdetailsComponent>, @Inject(MAT_DIALOG_DATA) public transaction: ITransactionsService, 
private authService: AuthService, private jwt: AuthorizationService ) {
  
  /* constructor must inicialize transaction object accessing each subvalue of the first item in the object array, don't know why 
      but the object that is injected via MAT_DIALOG_DATA cant be accessed at once like other objects.*/


  this.transaction.transactionId= this.transaction['transaction']['transactionId'];
  this.transaction.submittedBy= this.transaction['transaction']['submittedBy'];
  this.transaction.lmstatus= this.transaction['transaction']['lmstatus'];
  this.transaction.status= this.transaction['transaction']['status'];
  this.transaction.submittedDate= this.transaction['transaction']['submittedDate'];
  this.transaction.ticket= this.transaction['transaction']['ticket'];
  this.transaction.transactionId= this.transaction['transaction']['transactionId'];
  this.transaction.comments= this.transaction['transaction']['comments'];

  this.activeuser = this.authService.getName();
     
      this.authenticated = this.authService.authenticated;    
      if(this.jwt.getRole(this.authService.getCachedToken()) == 'User' ){
        this.isUser = true;
      }
    
}
CloseWindow() {
  this.dialogRef.close()
}
}



