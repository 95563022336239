import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internet-compatibility-msg',
  templateUrl: './internet-compatibility-msg.component.html',
  styleUrls: ['./internet-compatibility-msg.component.css']
})
export class InternetCompatibilityMsgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
