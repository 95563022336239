import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, retryWhen, mergeMap, finalize } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';



@Injectable()

export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector
  ) { }

  get router(): Router {
    return this.injector.get(Router);
  };


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.get("Skip")) {
      return next.handle(request);
    }
    else {
      return next.handle(request)
        .pipe(
          retryWhen(genericRetryStrategy()),
          catchError(error => {
            let errorMessage = '';
            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Client-side Error: ${error.error}`;
            } else {
              // server-side error
              errorMessage = `Server-side Error: ${error.error}`;
            }

            console.log(errorMessage)
            setTimeout(() =>
              this.router.navigate(["Error", { state: JSON.stringify(error) }])
            , 200);
            return throwError(error)
          })
        );
  }
}
}

const genericRetryStrategy = ({
  maxRetryAttempts = 1,
  scalingDuration = 1000,
  excludedStatusCodes = []
}: {
  maxRetryAttempts?: number,
  scalingDuration?: number,
  excludedStatusCodes?: number[]
} = {}) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      
      const retryAttempt = i + 1;
      console.log("ERROR N° " + (retryAttempt-1) + ": " + error)
      // if maximum number of retries have been met
      // or response is a status code we don't wish to retry, throw error
      if (
        retryAttempt > maxRetryAttempts ||
        excludedStatusCodes.find(e => e === error.status)
      ) {
        return throwError(error);
      }
      console.log(
        `Attempt ${retryAttempt}: retrying in ${retryAttempt *
          scalingDuration}ms`
      );
      // retry after 1s, 2s, etc...
      return timer(retryAttempt * scalingDuration);
    }),
    finalize(() => console.log('We are done!'))
  );
};