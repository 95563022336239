import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BroadcastService } from '@azure/msal-angular';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {


    private user = null;
    private token = null;
    private subscription: Subscription;
    constructor(private msalService: MsalService, private msalbroadcastService: BroadcastService) {

    }

    ngOnInit() {
        console.log('init');
        this.subscription = this.msalbroadcastService.subscribe('msal:acquireTokenFailure', (payload) => {
            this.msalService.loginRedirect();
        });
    }


    ngOnDestroy() {
        console.log('destroy');
        this.msalbroadcastService.getMSALSubject().next(1);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }


    public login(): any {
        try {
            this.msalService.loginRedirect();
        } catch (e) {
            console.log("oh no, there's an error");
        }
    }

    logout() {
        console.log('logout');
        this.msalService.logout();
    }

    get authenticated(): boolean {

        let isLoggedIn = this.msalService.getUser() != null;
        console.log('authenticated ' + isLoggedIn);
        return isLoggedIn;
    }

    getName(): string { 
        let isLoggedIn = this.msalService.getUser() != null;
        if (isLoggedIn) {
            this.user = this.msalService.getUser();
            console.log(this.user.name);
            return this.user.name;
        }
    }

    getMail(): string {
        let isLoggedIn = this.msalService.getUser() != null;
        if (isLoggedIn) {
            this.subscription = this.msalbroadcastService.subscribe('msal:loginSuccess', (payload) => {
                this.user = this.msalService.getUser();
            });

            var exxon_mail = this.user.displayableId;
            return exxon_mail;
        }
    }

    getCachedToken(): string {
        if (this.msalService.getUser() != null) {             
            return localStorage.getItem('msal.idtoken');
        } else {
            return '';
        }
    }

    public completeAuthentication(): void {
        console.log('completeAuthentication');
        this.subscription = this.msalbroadcastService.subscribe('msal:loginSuccess', (payload) => {
            this.user = this.msalService.getUser();
        });
    }
}
