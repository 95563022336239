import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface ComponentCanBeDeactivated{
  canDeactivate(): boolean;
  performTaskForDeactivation(): void
}

@Injectable({
  providedIn: 'root'
})
export class CanExitWithoutSavingGuard implements CanDeactivate<ComponentCanBeDeactivated>{
  canDeactivate(component: ComponentCanBeDeactivated, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (!component.canDeactivate()) {
      if (confirm("The actual transaction will be cancelled. Are you sure you want to leave?")){
        component.performTaskForDeactivation()
        return true;
      }
      else
        return false;
    }
    return true;
  }
}
